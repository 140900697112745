
:-moz-user-disabled {
  cursor: not-allowed;
}

:-moz-handler-disabled {
  cursor: not-allowed;
}

.disabled {
  cursor: not-allowed;
}

a {
  text-decoration: none;
  color: unset;
}

.MU-MuiSvgIcon-root:hover {
  cursor: pointer !important;
}

.MU-MuiFormControl-root {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.role-permission-update-page {
  .icon {
    cursor: pointer;
  }

  .module-list {
    .module-name {
    }
  }
}

.processes-detail-dialog {
  .row-type-1 {
    background-color: lightcoral !important;
  }
}

.aside {
  .dropdown {
    list-style: none;

    ul {
      li {

      }
    }
  }
}

.FilterRuleWrapper {
  margin: .5rem 0;
  padding: .5rem;
}

.FilterRuleWrapper:hover {
  border: 1px dashed #c3c3c3;
}

.MU-MuiFilledInput-root{
  background: white !important;
}

#tags-filled-label{
  left: -.7rem !important;
}
